import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import Helper from '../../util/Helper';
/* import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; */

import axios from 'axios';
import DatePickerModal from '../plugin/DatePickerModal';

const SearchFormMob = () => {
    /* const [fromPlace, setFromPlace] = useState('');
    const [toPlace, setToPlace] = useState('');
    const [cities, setCities] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiToken = process.env.REACT_APP_API_TOKEN;
    useEffect(() => {
        // Fetch cities list from API endpoint using Axios
        axios.get(apiUrl+'/get-cities-list', { 
            headers: { 
                'YESBUS_API_KEY': apiKey,
                Authorization: `Bearer ${apiToken}`,
            }
        })
        .then((response) => setCities(response.data.data))
        .catch((error) => {
            console.error('Error fetching cities:', error);
        });
    }, []); */
    const [cities, setCities] = useState([]);
    const [nearestBardingPnt, setNearestBardingPnt] = useState(null);
    const [showDatepickerModal, setShowDatepickerModal] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiToken = process.env.REACT_APP_API_TOKEN;

    const [selectedSourceCity, setSelectedSourceCity] = useState(null);
    const [filteredSourceCities, setFilteredSourceCities] = useState([]);

    const [selectedDestCity, setSelectedDestCity] = useState(null);
    const [filteredDestCities, setFilteredDestCities] = useState([]);

    const [selectedDate, setSelectedDate] = useState(new Date());

    const history = useNavigate();
    const destCityRef = useRef(null);

    const handleSourceCityChange = (event, newValue) => {
        setSelectedSourceCity(newValue);
    };

    const handleDestCityChange = (event, newValue) => {
        setSelectedDestCity(newValue);
    };

    const handleSourceCitySearch = (event, value) => {
        const searchTerm = value.trim().toLowerCase();
        const filteredOptions = cities.filter((city) =>
        city.city_name.toLowerCase().includes(searchTerm)
        );
        setFilteredSourceCities(filteredOptions);
    };

    const handleDestCitySearch = (event, value) => {
        const searchTerm = value.trim().toLowerCase();
        const filteredOptions = cities.filter((city) =>
          city.city_name.toLowerCase().includes(searchTerm)
        );
        setFilteredDestCities(filteredOptions);
    };
    
    const getOptionLabel = (option) => option.city_name;

    /* const handleBusSearchForm = (e) => {
        e.preventDefault();
        // Perform the search logic or any other action
        console.log("From:", selectedSourceCity);
        console.log("To:", selectedDestCity);
        console.log("Journey Date:", selectedDate);
    
        // Generate the slug using selected values
        const slug = `/bus-list/${selectedSourceCity.city_name.toLowerCase()}-to-${selectedDestCity.city_name.toLowerCase()}/${selectedDate.getDate()}-${selectedDate.getMonth() + 1}-${selectedDate.getFullYear()}`;
    
        // Redirect to the desired page
        history(slug);
    }; */


    const handleBusSearchForm = (e) => {
        e.preventDefault();
        if(selectedSourceCity==null){
            document.getElementById('source_fld_mob').focus();
            return false;
        }else if(selectedDestCity==null){
            document.getElementById('destination_fld_mob').focus();
            return false;
        }else if(selectedDate==null){
            document.getElementById('entryDate').focus();
            return false;
        }
        const sourceSlug = selectedSourceCity.city_name; // Get the city slug for source city
        const destSlug = selectedDestCity.city_name; // Get the city slug for destination city
        const dateSlug = formatDate(selectedDate); // Get the formatted date slug
        const slug = `bus/${sourceSlug}-to-${destSlug}/${dateSlug}`; // Combine the slugs
        history(slug); // Navigate to the new page with the generated slug
    };
    
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}-${month}-${year}`;
    };

    // console.log(selectedDate);

    const getNearestBardingPnt = () => {
        let unique_token = localStorage.getItem('unique_token');
        axios.get(apiUrl+'/get-geo-location-details/'+unique_token, { 
            headers: { 
                'YESBUS_API_KEY': apiKey,
                Authorization: `Bearer ${apiToken}`,
            }
        })
        .then((response) => {
            /* let geoLat = response.data.data.latitude;
            let geoLong = response.data.data.longitude;
            if(geoLat && geoLong){
                axios.get(`https://geocode.maps.co/reverse?lat=${geoLat}&lon=${geoLong}`)
                .then((response) => {
                    // console.log(response.data.display_name);
                    setNearestBardingPnt(response.data.display_name);
                })
                .catch((error) => {
                    console.error('Error fetching nearest boarding point:', error);
                });
            } */
            setNearestBardingPnt(response.data.data.location);
        })
        .catch((error) => {
            console.error('Error fetching cities:', error);
        });        
    }
    useEffect(() => {
        // Fetch cities list from API endpoint using Axios
        axios.get(apiUrl+'/get-cities-list', { 
            headers: { 
                'YESBUS_API_KEY': apiKey,
                Authorization: `Bearer ${apiToken}`,
            }
        })
        .then((response) => {
            setCities(response.data.data);
            setFilteredSourceCities(response.data.data);
            setFilteredDestCities(response.data.data);
        })
        .catch((error) => {
            console.error('Error fetching cities:', error);
        });

        getNearestBardingPnt();
        const interval = setInterval(() => {
            getNearestBardingPnt();
        }, 600000); // 10 minutes in milliseconds
        return () => clearInterval(interval);

    }, []);

    



    useEffect(()=>{
        if(selectedSourceCity){
            document.getElementById('destination_fld_mob').focus();
            //destCityRef.current.querySelector('input').focus();
        }
    },[selectedSourceCity]);
    useEffect(()=>{
        if(selectedDate){
            document.body.classList.remove('body-no-scroll');
            setShowDatepickerModal(false);
        }
    },[selectedDate]);
    const options = { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = selectedDate.toLocaleDateString('en-US', options);


    return (
        <>
            <form className="">
                <div className="row">
                    <div className="col-1 p-0">
                        <img src="/images/Icon metro-location_1.svg" className="img1" />
                        <br/>
                        <img src="/images/Line 11.svg" className="img2" />
                        <br/>
                        <img src="/images/Icon metro-location_1.svg" className="img3" />
                    </div>
                    <div className="col-11 p-2">
                        {
                            nearestBardingPnt &&
                            <div style={{fontSize : '14px', color: 'rgb(217, 57, 50)', fontWeight: '600'}} className='blink_boarding'>
                                {nearestBardingPnt} is your nearest boarding point
                            </div>
                        }
                        
                        <div className="form-group">
                            <label className="mb-2">From</label>
                            <br/>
                            {/* <Autocomplete
                            getItemValue={(item) => item.city_name}
                            items={Object.values(cities)}
                            renderItem={(item, isHighlighted) => (
                                <div style={{ background: isHighlighted ? 'lightgray' : 'white' }} key={item.id}>
                                {item.city_name}
                                </div>
                            )}
                            value={fromPlace}
                            onChange={(e) => setFromPlace(e.target.value)}
                            onSelect={(value) => setFromPlace(value)}
                            inputProps={{
                                placeholder: 'Enter Source',
                            }}
                            style={{width:'100%'}}
                            /> */}
                            <Autocomplete
                                options={filteredSourceCities}
                                value={selectedSourceCity}
                                onChange={handleSourceCityChange}
                                onInputChange={handleSourceCitySearch}
                                getOptionLabel={getOptionLabel}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                    <input {...params.inputProps} placeholder="Enter Source" id="source_fld_mob" className="form-control" />
                                    </div>
                                )}
                                renderOption={(props, option) => (
                                    <li key={option.id} {...props}>{option.city_name}</li>
                                )}
                                style={{width:'100%'}}
                            />
                        </div>
                        <div className="form-group">
                            <label className="mb-2">To</label>
                            <br/>
                            {/* <Autocomplete
                            getItemValue={(item) => item.city_name}
                            items={Object.values(cities)}
                            renderItem={(item, isHighlighted) => (
                                <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                {item.city_name}
                                </div>
                            )}
                            value={toPlace}
                            onChange={(e) => setToPlace(e.target.value)}
                            onSelect={(value) => setToPlace(value)}
                            inputProps={{
                                placeholder: 'Enter Destination',
                            }}
                            /> */}
                            <Autocomplete
                                options={filteredDestCities}
                                value={selectedDestCity}
                                onChange={handleDestCityChange}
                                onInputChange={handleDestCitySearch}
                                getOptionLabel={getOptionLabel}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                    <input {...params.inputProps} id="destination_fld_mob" placeholder="Enter Destination" className="form-control" />
                                    </div>
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>{option.city_name}</li>
                                )}
                            />
                        </div>
                    </div>
                    {/* <div className="col-2">
                        <img src="/images/swap.png" className="img4"/>
                    </div> */}
                </div>
                <div className="row mobile-date-box pl-2">
                    <div className="col-1 p-0"></div>
                    <input style={{display: "none"}} className=""/>
                    <div className="col-11 pb-3">
                        <div className="mob-dt-big" onClick={()=>{setShowDatepickerModal(true);document.body.classList.add('body-no-scroll')}}> Journey Date
                        {/* <DatePicker id="entryDate" className="form-control calendar" placeholder="Journey Date" selected={selectedDate} onChange={(date) => setSelectedDate(date)} dateFormat="eee, d MMM, yyyy" /> */}
                        {/* <DatePickerCalendar locale={enGB} /> */}
                            {/* <label>Sat, 21 October, 2023</label> */}
                            <br/>
                            <label>{formattedDate}</label>
                        </div>
                    </div>
                    {/* <div className="col-2 p-1"></div> */}
                </div>
                <div className="row pl-2">
                    <div className="col-1 p-0"></div>
                    <div className="col-11 pb-3 pr-2">
                        <div className="mob-bus_search">
                            <button type="submit" className="btn mob-bt" onClick={handleBusSearchForm}>Search Buses</button>
                        </div>
                    </div>
                    {/* <div className="col-2 p-1"></div> */}
                </div>
            </form>
            {
                showDatepickerModal && 
                <DatePickerModal showmodal={showDatepickerModal} modalStatus={setShowDatepickerModal} chooseDate={setSelectedDate} selectedDate={selectedDate}/>
            }
            
        </>
    )
}

export default SearchFormMob